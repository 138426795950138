export const environment = {
  production: false,
  staging: true,
  apiUrl: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '769098a1-eca6-422d-b15f-20d142f4e554',
  // authority: 'https://triple-a.services4eo.com',
  // clientId: 'c1d64a5a-b11c-45b5-a9e6-170fbc7572b9',
  scope: 'profile openid email user_name gsc4eo',
  geoServerUrl: 'https://webgeo2.hidrografico.pt/geoserver/ows',
  apiKey: 'ae03ec27-71ac-45c3-817c-4a5334edcfec',
  paypal_clientID: 'AZ5-jh2EW9Mm6Lyrns15sQfUfWqg3jehR2sEv3vnVs6xWakUawUsopPlkRB8iyo6BoJvN8Flh_hr8eXu',
  spaceChainApiKey: 'LQ6aXfsKKR9u1WxgcELMJYAY6XNzxpN5cueOZa35',
  spaceChainWalletURL: 'https://082cd9qz7d.execute-api.eu-west-2.amazonaws.com/dev',
  deimosWalletAddress: '0x0CdA49DF14D116442c54D37cf7f08B855F289128',
  //? External links
  externalLinks: {
    deimos: 'https://elecnor-deimos.com',
    esa: 'https://www.esa.int',
    support: 'https://service4eo.atlassian.net/servicedesk',
    linkedin:'https://www.linkedin.com/company/store4eo/about/',
    twitter:'https://twitter.com/store4eo',
    requestAreaUrl:'https://service4eo.atlassian.net/servicedesk/customer/portal/9/group/44/create/133',
    youtube:''
  },

  cms: {
    url: 'https://cms4eo.staging.services4eo.com',
    api: 'https://cms4eo.staging.services4eo.com/api',
    website: 'st4eo',
  },
  downloadAllHelp:{
    url:'https://en.wikipedia.org/wiki/Comparison_of_download_managers'
  },
  geoportalUrl: 'https://geoportal.staging.services4eo.com/home',
  outputFilterPattern: '',
  analyticsID: 'G-RJQFR4S2G3',
  storageUrl: 'https://data-lake.reference.services4eo.com'
};
